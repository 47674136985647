<template>
  <span class="view-activity-modal" v-if="canView">
    <b-modal
      dialog-class="modal-md"
      :id="getId()"
      ok-only
      ok-variant="secondary"
      ok-title="Fermer"
    >
      <template v-slot:modal-title>
        <b><i :class="activityIcon"></i> {{ title }}</b>
      </template>
      <div v-if="activity">
        <ul class="activity-modal">
          <li><b>{{ activity.name }}</b></li>
          <li>
            <router-link :to="getLinkToActivity()" class="btn btn-primary btn-block">
              Voir l'activité
            </router-link>
          </li>
          <li>&nbsp;</li>
          <li v-if="activity.disabled"><b><i class="fa fa-lock"></i>Suspendue</b></li>
          <li v-if="activity.day">
            <i class="fa fa-calendar-times"></i> <b>Jour:</b> {{ activity.day }}
          </li>
          <li v-if="activity.startTime">
            <i class="fa fa-clock"></i> <b>Horaire:</b> {{ activity.startTime }}&nbsp;
            <span v-if="activity.endTime">- {{ activity.endTime }}</span>
          </li>
          <li v-if="!activity.startTime && activity.endTime">
            <i class="fa fa-clock"></i> <b>Horaire de fin:</b> {{ activity.endTime }}
          </li>
          <li v-if="activity.startDate">
            <i class="fa fa-calendar-check"></i> <b>Début:</b>&nbsp;
            {{ activity.startDate | dateToString('DD/MM/YYYY') }}
          </li>
          <li v-if="activity.duration > 0">
            <i class="fa fa-clock"></i> <b>Durée:</b> {{ duration(activity.duration) }}
          </li>
          <li v-if="activity.place.id">
            <i class="fa fa-location-dot"></i> <b>Lieu:</b> {{ activity.place.name }}
          </li>
          <li v-if="activity.getKeywords().length">
            <b><i class="fa fa-key"></i> Mots-clefs</b><br />
            <span v-for="keyword of activity.getKeywords()" :key="keyword">
              {{ keyword }}
            </span>
          </li>
          <li v-if="activity.minimumParticipants || activity.maximumParticipants">
          <i class="fa fa-users"></i> <b>Nb de Participants</b>
          <div v-if="activity.minimumParticipants"><b>Min:</b> {{ activity.minimumParticipants }}</div>
          <div v-if="activity.maximumParticipants"><b>Max:</b> {{ activity.maximumParticipants }}</div>
          </li>
          <li v-if="activity.about">
            <i class="fa fa-list"></i> <b>A propos</b>
            <div>{{ activity.about }}</div>
          </li>
          <li v-if="activity.conditions">
            <i class="fa fa-check-double"></i> <b>Conditions</b>
            <div>{{ activity.conditions }}</div>
          </li>
          <li>
            <i class="fa fa-euro"></i> <b>{{ activityPricingRuleName }}</b>
            <div>{{ activity.getPriceName() }}</div>
          </li>
          <li v-if="hasPerm('activities.view_activitymanager')">
            <i class="fa fa-people-line"></i> <b>Animateurs</b>&nbsp;
            <div v-if="activity.managedBy.length === 0" class="empty-text">
              Aucun animateur
            </div>
            <div v-for="manager of activity.managedBy" :key="manager.id" class="activity-manager">
              <span>
                {{ manager.individual.fullName() }}
              </span>
              <span v-if="manager.individual.cellPhone">
                <phone-link :phone="manager.individual.cellPhone"></phone-link>
              </span>
              <span v-if="manager.individual.proPhone">
                <phone-link :phone="manager.individual.proPhone"></phone-link>
              </span>
              <span v-if="manager.individual.email">
                <email-link :email="manager.individual.email"></email-link>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import PhoneLink from '@/components/Controls/Links/PhoneLink.vue'
import EmailLink from '@/components/Controls/Links/EmailLink.vue'
import { ActivitiesMixin } from '@/mixins/activities'
import { BackendMixin } from '@/mixins/backend'
import { duration } from '@/filters/texts'
import store from '@/store'

export default {
  name: 'view-activity-modal',
  mixins: [BackendMixin, ActivitiesMixin],
  props: {
    activity: Object,
  },
  components: {
    EmailLink, PhoneLink,
  },
  data() {
    return {
      errorText: '',
    }
  },
  computed: {
    canView() {
      return this.hasPerm('activities.view_coreactivity')
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    activityIcon() {
      if (this.activity) {
        if (this.activity.isYouthHome()) {
          return this.youthHomeIcon
        } else {
          const menu = this.getActivityMenu()
          if (menu) {
            return menu.icon
          } else {
            return 'fas fa-chess-knight'
          }
        }
      }
      return ''
    },
    title() {
      if (this.activity) {
        if (this.activity.isYouthHome()) {
          return this.youthHomeLabel
        } else {
          const menu = this.getActivityMenu()
          if (menu) {
            return menu.label
          } else {
            return 'Activité'
          }
        }
      }
      return ''
    },
  },
  watch: {
    activity: function(newValue, oldValue) {},
  },
  methods: {
    duration,
    ...mapActions(['addError', 'addSuccess']),
    getId() {
      return 'bv-modal-view-activity'
    },
    getLinkToActivity() {
      return { name: 'activities-detail', params: { activityId: '' + this.activity.id, }, }
    },
  },
  mounted() {
  },
}
</script>
<style lang="less" scoped>
ul.activity-modal {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.activity-modal li {
    padding: 4px;
    margin-bottom: 4px;
    border-bottom: solid 1px #ccc;
  }

  ul.activity-modal li:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }
</style>
