<template>
  <div class="weekly-calendar" ref="calendar" style="position: relative;">
    <div class="header" v-if="!hideDates">
      <div :style="col0Style(false)">&nbsp;</div>
      <span>
        <b>
          Du {{ fullDayName(mondayDate) }} au {{ fullDayName(lastDate) }} -
          Semaine N°{{ weekNumber(mondayDate) }}
        </b>
      </span>
    </div>
    <div class="header">
      <div :style="col0Style(false)">&nbsp;</div>
      <div
        v-for="day of weekDays"
        :key="dayName(day)"
        :style="colStyle(true, day)"
      >
        <a href @click.prevent="onSelectCol(day)">{{ dayName(day) }}</a>
        <span style="margin: 0 2px; cursor: pointer;" v-if="canViewComments" class="no-print">
          <i
            class="fa fa-comment"
            :class="{ 'no-comment': !hasComments(day) }"
            @click.prevent="onCommentsClicked(day)"
          >
          </i>
        </span>
      </div>
    </div>
    <div class="header-comments" v-if="showDateComments">
      <div :style="col0Style(false)">&nbsp;</div>
      <div
        v-for="day of weekDays"
        :key="dayName(day)"
        :style="colStyle(true, day)"
      >
        <span v-if="hasComments(day)">
          <span v-if="canEditComments">
            <a href @click.prevent="onDayCommentsClicked(day)" style="cursor: pointer;">
              <div v-html="daysComments(day)"></div>
            </a>
          </span>
          <span v-else>
            <div v-html="daysComments(day)"></div>
          </span>
        </span>
        <span v-else-if="canAddComments">
          <a href @click.prevent="onDayCommentsClicked(day)" style="cursor: pointer;">
            <i class="fa fa-plus"></i>
          </a>
        </span>
      </div>
    </div>
    <div class="" v-for="hour of hours" :key="hour">
      <div :style="col0Style(true)">{{ hour }}:00</div>
      <div
        v-for="day of weekDays" :key="dayName(day)"
        :style="colStyle(false, day)"
        @click.prevent="onClickCalendar(day, hour)"
      >
        &nbsp;
      </div>
    </div>
    <div
      class="event"
      v-for="event of calEvents"
      :key="'ev' + event.id"
      :style="eventStyle(event)"
      @click.prevent="onClickEvent(event)"
    >
      <div v-if="showComments">
        <div v-if="event.comments">
          {{ event.comments }}
        </div>
      </div>
      <div v-else>
        {{ event.title }}
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { dateToString } from '@/filters/texts'
import { escapeHtml } from '@/utils/html'

export default {
  name: 'WeeklyCalendar',
  props: {
    events: Array,
    weekDate: Date,
    forceWidth: {
      type: [String, Number],
      default: 0,
    },
    showComments: {
      type: Boolean,
      default: false,
    },
    dateComments: {
      type: Array,
      default: function() { return [] },
    },
    canAddComments: {
      type: Boolean,
      default: true,
    },
    canEditComments: {
      type: Boolean,
      default: true,
    },
    canDeleteComments: {
      type: Boolean,
      default: true,
    },
    canViewComments: {
      type: Boolean,
      default: true,
    },
    allowHideSunday: {
      type: Boolean,
      default: true,
    },
    hideDates: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      calendarWidth: 100,
      selectedCol: null,
      calEvents: [],
      showDateComments: false,
    }
  },
  computed: {
    mondayDate() {
      const start = moment(this.weekDate)
      const weekDay = start.weekday()
      start.add(-weekDay, 'days')
      return start.toDate()
    },
    showSunday() {
      return (
        !this.allowHideSunday ||
        (this.events.filter(event => moment(event.start).weekday() === 6).length > 0)
      )
    },
    lastDate() {
      const start = moment(this.weekDate)
      const weekDay = start.weekday()
      const delta = this.showSunday ? 6 : 5
      start.add(-weekDay + delta, 'days')
      return start.toDate()
    },
    calendar() {
      if (this.$refs.calendar) {
        return this.$refs.calendar
      } else {
        return null
      }
    },
    numberOfDays() {
      return this.showSunday ? 7 : 6
    },
    weekDays() {
      const weekDays = []
      const start = moment(this.mondayDate)
      for (let index = 0; index < this.numberOfDays; index++) {
        const theDate = start.clone()
        theDate.add(index, 'days')
        weekDays.push(theDate)
      }
      return weekDays
    },
    earlierEventHour() {
      let minHour = 7
      const eventHours = this.calEvents.filter(
        event => (moment(event.start).format('HH:mm') !== moment(event.end).format('HH:mm'))
      ).map(event => moment(event.start).local().hours())
      if (eventHours.length > 0) {
        const eventMinHour = Math.min(...eventHours)
        if (eventMinHour < minHour) {
          minHour = eventMinHour
        }
      }
      return minHour
    },
    hours() {
      let earlierEventHour = this.earlierEventHour
      return Array.from(Array(24).keys()).filter(hour => hour >= earlierEventHour)
    },
    col0Width() {
      return 40 // (+this.forceWidth) ? 20 : 40
    },
    hourHeight() {
      return 30
    },
  },
  watch: {
    events: function() {
      this.calEvents = this.events
    },
    forceWidth: function() {
      this.fillCalendarWidth()
    },
    showComments: function() {
    },
  },
  methods: {
    fillCalendarWidth() {
      if (+this.forceWidth) {
        this.calendarWidth = +this.forceWidth
      } else if (this.calendar) {
        const that = this
        this.$nextTick(function() {
          that.calendarWidth = that.calendar.clientWidth
        })
      }
    },
    dayName(day) {
      if (this.hideDates) {
        return moment(day).format('ddd')
      } else {
        return moment(day).format('ddd D')
      }
    },
    fullDayName(day) {
      return moment(day).format('dddd D MMMM YYYY')
    },
    weekNumber(day) {
      return moment(day).week()
    },
    isToday(day) {
      return moment(day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    },
    col0Style(header) {
      return {
        'background-color': header ? '#f0f0f0' : '#fff',
        width: '' + (this.col0Width) + 'px',
        height: '' + (this.hourHeight) + 'px',
        display: 'inline-block',
        padding: '1px',
        border: header ? 'solid 1px #888' : 'solid 1px transparent',
        'text-align': 'right',
        'font-size': this.forceWidth ? '9px' : '14px',
        verticalAlign: 'top',
      }
    },
    colWidth(day) {
      const theWidth = (+this.forceWidth) ? +this.forceWidth : this.calendarWidth
      const colWidths = theWidth - this.col0Width
      if (this.selectedCol) {
        const fmt = 'YYYY-MM-DD'
        const isSelected = moment(this.selectedCol).format(fmt) === moment(day).format(fmt)
        if (isSelected) {
          return Math.floor(colWidths * 3 / 4)
        } else {
          return Math.floor(colWidths / (4 * (this.numberOfDays - 1)))
        }
      } else {
        return Math.floor(colWidths / this.numberOfDays)
      }
    },
    eventTop(event) {
      const earlierEventHour = this.earlierEventHour
      const startHours = moment(event.start).local().hours()
      const startMinutes = moment(event.start).local().minutes()
      const headerCols = this.hideDates ? 1 : 2
      let pos1 = (startHours + (startMinutes / 60) - earlierEventHour) + headerCols
      return Math.floor(pos1 * this.hourHeight) + 'px'
    },
    eventHeight(event) {
      const earlierEventHour = this.earlierEventHour
      const startHours = moment(event.start).local().hours()
      const startMinutes = moment(event.start).local().minutes()
      const endHours = moment(event.end).local().hours()
      const endMinutes = moment(event.end).local().minutes()
      let pos1 = startHours + (startMinutes / 60) - earlierEventHour
      let pos2 = endHours + (endMinutes / 60) - earlierEventHour
      return Math.floor(((pos2 - pos1) * this.hourHeight)) + 'px'
    },
    eventLeft(event) {
      const weekDay = moment(event.start).weekday()
      const dayWidth = this.colWidth(event.start)
      let dayDelta = 0
      for (let index = 0; index < weekDay; index++) {
        const day = moment(this.mondayDate)
        day.add(index, 'days')
        dayDelta += this.colWidth(day.toDate())
      }
      let left = this.col0Width + dayDelta
      const delta = weekDay * ((+this.forceWidth) ? 3 : 0)
      left += delta
      if (event.siblings.count) {
        const eventWidth = Math.floor(dayWidth / event.siblings.count)
        left += (event.siblings.index * eventWidth)
      }
      return left + 'px'
    },
    eventWidth(event) {
      let dayWidth = this.colWidth(event.start)
      const delta = (+this.forceWidth) ? 5 : 0
      dayWidth += delta
      if (event.siblings.count) {
        const eventWidth = Math.floor(dayWidth / event.siblings.count)
        return '' + eventWidth + 'px'
      }
      return '0px'
    },
    colStyle(header, day) {
      let background = '#fff'
      if (this.isToday(day)) {
        background = header ? '#ffffc0' : '#fffff0'
      } else if (header) {
        background = '#f0f0f0'
      }
      return {
        'background-color': background,
        width: '' + (this.colWidth(day)) + 'px',
        height: '' + (this.hourHeight - 2) + 'px',
        display: 'inline-block',
        padding: '1px',
        border: header ? 'solid 1px #888' : 'solid 1px #f0f0f0',
        'text-align': 'center',
        'font-size': '12px',
        verticalAlign: 'top',
      }
    },
    eventStyle(event) {
      return {
        position: 'absolute',
        top: this.eventTop(event),
        left: this.eventLeft(event),
        height: this.eventHeight(event),
        width: '' + this.eventWidth(event),
        color: event.color,
        'background-color': event.backgroundColor,
        display: 'inline-block',
        'text-align': 'center',
        'font-size': '11px',
        overflow: 'hidden',
        cursor: (this.clickable) ? 'pointer' : '',
        verticalAlign: 'top',
        opacity: event.opacity || 1,
        border: 'solid 1px #fff',
      }
    },
    onClickEvent(event) {
      this.$emit('clicked', { event, })
    },
    onClickCalendar(day, hour) {
      const date = day.clone()
      date.hours(hour)
      this.$emit('new', { date, })
    },
    onResize() {
      this.fillCalendarWidth()
    },
    onSelectCol(day) {
      if (this.selectedCol === day) {
        this.selectedCol = null
      } else {
        this.selectedCol = day
      }
      this.calEvents = this.events
    },
    hasComments(day) {
      const dayString = dateToString(day, 'YYYY-MM-DD')
      return this.dateComments.filter(
        elt => dateToString(elt.date, 'YYYY-MM-DD') === dayString
      ).length > 0
    },
    daysComments(day) {
      const dayString = dateToString(day, 'YYYY-MM-DD')
      return this.dateComments.filter(
        elt => dateToString(elt.date, 'YYYY-MM-DD') === dayString
      ).map(elt => escapeHtml(elt.comments)).join('<br />')
    },
    onDayCommentsClicked(day) {
      this.$emit('comments-clicked', { day: day, })
    },
    onCommentsClicked(day) {
      this.showDateComments = !this.showDateComments
    },
  },
  mounted() {
    this.fillCalendarWidth()
    this.calEvents = this.events
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>
<style lang="less" scoped>
.no-comment {
  color: #ccc;
}
</style>
